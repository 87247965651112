import React from "react";
import theme from "theme";
import { Theme, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				מפגש ברלין
			</title>
			<meta name={"description"} content={"מפגש ברלין - מסעדת המבורגרים ז'בוטינסקי 61 רמת גן"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
		</Helmet>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"639068b75f45170024239f06"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfHead"} rawKey={"661fae3033a5a44ee91af3c8"} />
			<script place={"endOfBody"} rawKey={"661fb2710a02415681b09f45"}>
				{"<script src=\"https://cdn.enable.co.il/licenses/enable-L9106k5bc614ivu-0721-58225/init.js\"></script>"}
			</script>
		</RawHtml>
	</Theme>;
});